.main {
	padding-left: 0;
	padding-right: 0;
}

body.home {
	.main {
		background: $grey;

		.image {
			background-size: cover;
			background-position: center center;
		}

		.content {
			color: #fff;
			padding: 0 20px 20px;

			h2 {
				font-size: 18px;
				line-height: 30px;
				padding-top: 25px;
			}

			.service-txt {
				color: #fff;
				display: table;
				font-size: 16px;
				line-height: 18px;

				> * {
					display: table-cell;
					vertical-align: middle;
				}

				img {
					margin-right: 15px;
					width: 35px;
					height: auto;
					max-width: 100%;
				}
			}
		}
	}
}

body.services {
	.image {
		display: table;
		width: 100%;
		background-size: cover;
		background-position: top center;

		.container {
			display: table-cell;
			vertical-align: bottom;
			font-size: 18px;
			line-height: 24px;

			div {
				color: #000;
				background: rgba(255,255,255,0.75);
				margin: 0 auto;
				padding: 20px 30px;
			}
		}
	}

	.services {
		.container {
			hr {
			    height: 3px;
			    width: 100%;
			    background: #ef9600;
			    margin-top: -2px;
			    margin-bottom: 0px;
			    border: 0;
			}

			> div {
				background: #767676;
				margin: 0 auto;
				padding: 30px 35px;
				color: #000;
			}

			.service {
				margin-bottom: 40px;

				@media (max-width: 992px) {
					margin-bottom: 20px;
				}

				img {
					float: left;
					width: 55px;
					height: auto;

					@media (max-width: 700px) {
						margin-bottom: 15px;
					}
				}

				.content {
					float: left;
					padding: 0 15px 15px 30px;
					width: 90%;

					@media (max-width: 700px) {
						width: 100%;
						padding: 0;
					}

					p {
						font-family: 'Arial', sans-serif;
						font-size: 14px;
						line-height: 24px;
					}

					h3 {
						margin-top: 0;
					}
				}
			}
		}
	}
}

body.bio {
	.main {
		padding-top: 60px;
	}

	.primary-bio {
		margin-bottom: 100px;
	}

	.bio-sidebar {
		@media (max-width: 768px) {
			margin-bottom: 40px;
		}

		> img {
			margin-bottom: 7px;

			@media (max-width: 768px) {
				display: block;
				margin: 0 auto 7px;
			}
		}

		.title, .cta {
			background: #767676;
			padding: 45px 50px 20px;
			color: #000;
			font-size: 16px;
			line-height: 24px;

			@media (max-width: 992px) {
				padding: 20px;
			}
		}

		.quote {
			position: relative;
			background: #ef9600;
			padding: 25px 70px;
			color: #fff;
			font-size: 20px;
			line-height: 28px;
			margin-left: -10px;
			margin-right: -10px;
			text-align: center;
			font-family: "Minion Pro Italic";

			@media (max-width: 992px) {
				padding: 20px;
			}

			span:first-of-type {
			    width: 0;
			    height: 0;
			    border-top: 10px solid #ef9600;
			    border-left: 10px solid transparent;
			    position: absolute;
			    bottom: -10px;
			    left: 0;
			}

			span:last-of-type {
				width: 0;
			    height: 0;
			    border-top: 10px solid #ef9600;
			    border-right: 10px solid transparent;
			    position: absolute;
			    bottom: -10px;
			    right: 0;
			}
		}

		.cta {
			span.orange-btn {
				display: block;
				text-align: center;
			}
		}
	}

	.bio-content {
		color: #fff;
		font-size: 18px;
		line-height: 30px;
		padding-left: 60px;

		@media (max-width: 992px) {
			padding-left: 30px;
		}

		@media (max-width: 768px) {
			padding-left: 15px;
		}
	}

	.companies {
		background: #fff;
		padding: 58px 15px 32px;

		.company {
			padding: 0 120px 40px;
			font-size: 16px;
			line-height: 24px;
			color: #4A4A4A;

			@media (max-width: 992px) {
				padding: 0 30px 20px;
			}

			img {
				margin-bottom: 25px;
			}

			&:nth-child(odd) {
				border-right: 1px solid #EF9600;

				@media (max-width: 768px) {
					border-right: 0;
				}
			}
		}
	}
}

body.connect {
	color: #fff;
	font-size: 18px;
	line-height: 30px;

	.main {
		padding: 60px 0;
	}

	.gform_wrapper {
		color: #9b9b9b;

		.field_sublabel_below .ginput_complex.ginput_container label {
			display: none;
		}

		.ginput_complex input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=image]):not([type=file]),
		input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),
		textarea.medium {
			width: 300px;
			max-width: 100%;
			border: 0;
		}

		.gform_footer input.button, .gform_wrapper .gform_footer input[type=submit] {
			height: 50px;
			width: 150px;
			max-width: 100%;
			border-radius: 4px;
			background: #EF9600;
			color: #fff;
			font-size: 18px;
			border: 0;
		}

		.top_label .gfield_label {
			font-size: 16px;
			color: #9b9b9b;
			font-weight: normal;
		}

		.gfield_required {
		    color: #EF9600;
		}
	}

	#gform_confirmation_wrapper_1 {
		background: transparent url('../images/Envelope_image@2x.png') no-repeat;
		background-size: contain;
		background-position: 50% 50%;

		> div {
			padding: 40px; 
		}
	}
}