footer.content-info {
	text-align: center;
	padding: 15px 0 5px;
	color: #9b9b9b;
	font-size: 16px;
	background: #fff;

	> div {
		display: table;

		> * {
			display: table-cell;
			vertical-align: middle;

			@media (max-width: 768px) {
				display: block;
				margin-bottom: 10px;
			}
		}
	}

	ul.social {
		list-style-type: none;
		display: inline-block;

		li {
			display: inline-block;
			margin-right: 50px;

			@media (max-width: 768px) {
				margin-right: 10px;
			}

			&:last-child {
				margin-right: 0;
			}

			a {
				text-indent: -999em;
				height: 33px;
				width: 33px;
				background-size: cover;
				display: block;

				&.facebook {
					background-image: url('../images/Facebook@2x.png');
				}

				&.twitter {
					background-image: url('../images/Twitter@2x.png');
				}

				&.linkedin {
					background-image: url('../images/LinkedIn@2x.png');
				}

				&.instagram {
					background-image: url('../images/Instagram@2x.png');
				}
			}
		}
	}
}