header.banner {
	padding-bottom: 7px;
	background: #fff;
    position: fixed;
    z-index: 999;
    width: 100%;

	a.brand {
		display: block;
		float: left;
		margin-top: 15px;

		.logo {
			height: 35px;
			width: 33px;
			margin-right: 18px;
		}

		.words {
			height: 23px;
			width: 190px;
		}
	}

	a.hammy {
	    position: absolute;
	    top: 0;
	    right: 15px;
	    display: block;
	    margin: 15px 5px 0 0;
	    width: 35px;
	    height: 30px;
	    -webkit-transform: rotate(0);
	    -o-transform: rotate(0);
	    transform: rotate(0);
	    -webkit-transition: .5s ease-in-out;
	    -o-transition: .5s ease-in-out;
	    transition: .5s ease-in-out;
	    cursor: pointer;

		@media (min-width: 784px) {
			display: none;
		}

		&.open {
			span {
				&:nth-child(1), &:nth-child(4) {
					top: 18px;
					width: 0;
					left: 50%;
				}

				&:nth-child(2) {
					transform: rotate(45deg);
				}

				&:nth-child(3) {
					transform: rotate(-45deg);
				}
			}
		}

		span {
		    display: block;
		    position: absolute;
		    height: 5px;
		    width: 100%;
		    background: #ef9600;
		    border-radius: 9px;
		    opacity: 1;
		    left: 0;
		    -webkit-transform: rotate(0);
		    -o-transform: rotate(0);
		    transform: rotate(0);
		    -webkit-transition: .25s ease-in-out;
		    -o-transition: .25s ease-in-out;
		    transition: .25s ease-in-out;

		    &:nth-child(1) {
		    	top: 0;
		    }

		    &:nth-child(2), &:nth-child(3) {
		    	top: 10px;
		    }

		    &:nth-child(4) {
		    	top: 20px;
		    }
		}
	}

	nav.nav-primary {
		float: left;
		margin-left: 95px;

		.nav {
			> li {
				float: left;

				> a {
					font-size: 16px;
					line-height: 16px;
					padding-top: 20px;
					color: #9b9b9b;
				    min-width: 100px;
    				text-align: center;
				}

				&.active, &:hover {
					> a {
						color: #fff;
						background: $orange;
						text-shadow: 1px 0 0 white;
					}
				}
			}
		}

		@media (max-width: 784px) {
			display: none;
			margin: 20px 0 0 0;
			width: 100%;

			.nav {
				> li {
					float: none;
				}
			}
		}
	}
}

.wrap {
	padding-top: 57px;
}