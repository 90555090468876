@font-face {
	font-family: 'Century Gothic';
	src: url('../fonts/GOTHIC.TTF');
	font-weight: normal;
}

@font-face {
	font-family: 'Century Gothic';
	src: url('../fonts/GOTHICB.TTF');
	font-weight: bold;
}

@font-face {
	font-family: 'Minion Pro Italic';
	src: url('../fonts/MinionPro-It_0.otf');
	font-style: italic;
}

body {
	font-family: 'Century Gothic';
	background: #282828;

	img {
		max-width: 100%;
		height: auto;
	}

	.orange-btn a, a .orangebtn {
	    background: #ef9600;
	    color: #fff;
	    font-size: 18px;
	    padding: 9px 9px 9px 30px;
	    border-radius: 4px;

	    @media (max-width: 1200px) {
			padding-left: 20px;
	    }

	    @media (max-width: 992px) {
	    	padding-left: 10px;
	    }

	    &:after {
	    	content: '';
	    	background: transparent url('../images/arrow-right@2x.png') no-repeat right center;
	    	height: 22px;
	    	width: 13.5px;
	    	background-size: cover;
	    	display: inline-block;
	    	margin: -4px 0 -4px 30px;

	    	@media (max-width: 1200px) {
	    		margin: -4px 0 -4px 10px;
	    	}
	    }
	}
}